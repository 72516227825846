import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

const Intro = () => {
  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
          }
        }
      }
    `,
  )

  const details = data.site.siteMetadata

  return (
    <>
      <header className="masthead">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-md-10 mx-auto">
              <div className="site-heading">
                <h1>{details.title}</h1>
                <span className="subheading">{details.description}</span>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  )
}

export default Intro
